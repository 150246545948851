import {Back, Col, Row} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
// components
import {Lottery} from "../../../blocks/lottery";
import "./style.scss";
import Header from "../../../blocks/header";
import {isMobile} from "react-device-detect";

export default function LotteryPage() {
    const navigate = useNavigate();
    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };

    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            <Row justify="center" gutter={32}>
                <Back navigate={() => navigate("/")} />
                <Col lg={{span: 24}} xs={{span: 24}}>
                    <Lottery />
                </Col>
            </Row>
        </>
    );
}
