import {Button, Form, Radio, Space, Typography} from "@bezlimit/bezlimit-ui";
import {t} from "i18next";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import "./style.scss";
//Store
//components
import ListRowBankCardsAutopayment from "./row";
import {InterceptorContext} from "../../../../../core/Contexts";
import {FormInstance} from "antd";
import SpaceAutoPaymentAmount from "./spacePaymentSum";
import UserCardService from "../../../../../services/UserCardService";
import PhoneField from "../../../../fields/phone";
import _ from "lodash";
import useFormHandler from "../../../../../core/hooks/useFormHandler";
import useOnChange from "../../../../../core/hooks/useOnChange";

export default observer(function AutopaymentDefaultCreate({
    onFinish,
    form,
    isMainPage,
    numberPhone,
    tariffName
}: TProps) {
    const context = useContext(InterceptorContext);
    const cards = UserCardService.cardsUser;
    const formInstance = useFormHandler(form);
    const onChange = useOnChange(formInstance);
    function isNumberPhone(phone: string) {
        return numberPhone ? true : !!phone && !(_.replace(phone!, /(\+7|\s|-)/gi, "").length < 10);
    }

    return (
        <Typography as="div" className="autopayment-modal--description-defaultCreate">
            <Form
                form={form}
                layout="horizontal"
                name="autopayment-form--description-defaultCreate"
                onFinish={onFinish}
                onFieldsChange={onChange}
                autoComplete="off"
                initialValues={{
                    phone: isMainPage ? "" : numberPhone,
                    card_id: null,
                    sum_payment: null,
                    radioauto_payment_amount: null
                }}
            >
                {isMainPage ? (
                    <PhoneField />
                ) : (
                    <Typography
                        as="p"
                        className="autopayment-form--description-defaultCreate-space"
                        color="#000000"
                    >
                        {t("Оплата номера ")} <span>{numberPhone}</span>
                        <br />
                        {t(" по тарифу ")}
                        {tariffName}
                    </Typography>
                )}
                <Typography
                    as="div"
                    className="autopayment-form--description-defaultCreate-wrapper"
                >
                    <Space size={10} direction="vertical">
                        <Space size={4} direction="vertical">
                            <Space size={21} direction="vertical">
                                <SpaceAutoPaymentAmount form={form} />
                            </Space>
                        </Space>
                    </Space>
                    <Typography
                        as="p"
                        className="autopayment-form--description-defaultCreate-space-saved-cards"
                        color="#000000"
                    >
                        {t("Ваши сохраненные карты")}
                    </Typography>
                    {!_.isEqual(cards.length, 0) ? (
                        <Form.Item
                            name="card_id"
                            rules={[
                                {
                                    required: true,
                                    message: t("Поле обязательно для заполнения")
                                }
                            ]}
                        >
                            <Radio.Group optionType="default">
                                {cards.map((card: any, index: any) => (
                                    <ListRowBankCardsAutopayment
                                        key={card.id}
                                        type={card.type}
                                        maskerPan={card.masked_pan}
                                        card={card}
                                    />
                                ))}
                            </Radio.Group>
                        </Form.Item>
                    ) : (
                        <>
                            <Typography className="description description-no-saved-cards description-no-saved-cards-modal">
                                {t("У вас нет сохраненных карт")}
                            </Typography>
                        </>
                    )}
                    <Typography
                        as="div"
                        color="#000000"
                        className="description-no-saved-cards-modal-add"
                        onClick={() => {
                            if (!isMainPage && numberPhone) {
                                localStorage.setItem("number-payment", numberPhone.toString());
                            }

                            UserCardService.postBankCardSave().then((res) =>
                                window.open(res.url, "_self")
                            );
                        }}
                    >
                        {t("Добавить новую карту")}
                    </Typography>
                </Typography>
                <Form.Item noStyle shouldUpdate>
                    {({getFieldsValue, getFieldsError}) => {
                        const {phone, card_id, sum_payment} = getFieldsValue();
                        const errors = form
                            .getFieldsError()
                            .some((el: {errors: string | any}) => el.errors.length);

                        const formIsComplete =
                            isNumberPhone(phone) && !!card_id && !!sum_payment && !errors;

                        return (
                            <Button
                                type={"primary"}
                                htmlType="submit"
                                disabled={!formIsComplete}
                                loading={context?.loadingBtn}
                                className="autopayment-modal--cancel-btn-defaultCreate"
                            >
                                {t("Оплатить")}
                            </Button>
                        );
                    }}
                </Form.Item>
            </Form>
        </Typography>
    );
});

type TProps = {
    isMainPage: boolean;
    onFinish: (props: {
        card_id: string | number;
        phone: string | number;
        sum_payment: string | number;
    }) => Promise<void>;
    form: FormInstance<any>;
    tariffName?: string;
    numberPhone?: number;
};
