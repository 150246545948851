import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React from "react";
import {isIOS, isMIUI, isSafari} from "react-device-detect";
import prizeGif from "../../../../images/prize.gif";

export default React.memo(function Prize() {
    return (
        <Typography as="div" className="bl-lottery-ng-prize">
            <Typography as="div" className="bl-lottery-ng-prize__title">
                <Typography as="div" className="bl-lottery-ng-prize__title__text">
                    Общий призовой фонд
                </Typography>
            </Typography>
            {isMIUI || isIOS || isSafari ? (
                <Typography as="div" className="bl-lottery-ng-prize__video">
                    <img src={prizeGif} alt="" />
                </Typography>
            ) : (
                <Typography
                    as="div"
                    className="bl-lottery-ng-prize__video"
                    dangerouslySetInnerHTML={{
                        __html: `
                    <video
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                      <source src="prize.mov" type='video/mp4; codecs="hvc1"' />
                      <source src="prize.webm" type='video/webm; codecs="vp8, vorbis"' />
                      </video>`
                    }}
                ></Typography>
            )}
        </Typography>
    );
});
