import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// store
import Store from "../../../../stores";
// components
import Place from "./place";
//core
import {ILotteryPlace} from "../../../../core/models/Lottery";

export default observer(function Places() {
    return (
        <Typography as="div" className="bl-lottery-ng-places">
            <Typography as="div" className="bl-places">
                {_.get(Store.lottery?.lottery, "awards_info.placeBlocks[0].places[0].image") ? (
                    <Typography as="div" className="bl-places__images">
                        {_.chain(
                            _.get(Store.lottery?.lottery, "awards_info.placeBlocks[0].places") ?? []
                        )
                            .filter(({image}) => image)
                            .map(({image}: ILotteryPlace) => (
                                <React.Fragment key={image}>
                                    <Typography
                                        as="div"
                                        dangerouslySetInnerHTML={{
                                            __html: `<img src="${image!}"  width="189" alt="" />`
                                        }}
                                    />
                                </React.Fragment>
                            ))
                            .valueOf()}{" "}
                    </Typography>
                ) : (
                    _.chain(
                        _.get(Store.lottery?.lottery, "awards_info.placeBlocks[0].places") ?? []
                    )
                        .filter(({title, sum}) => /^[\d]{1}\sместо$/g.test(title) && sum)
                        .orderBy(["title"], ["asc"])
                        .map((i: ILotteryPlace) => (
                            <React.Fragment key={i.title}>
                                <Place {...i} />
                            </React.Fragment>
                        ))
                        .valueOf()
                )}
            </Typography>
        </Typography>
    );
});
