import {Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
import {useMemo} from "react";
import {ILotteryPlace} from "../../../../../core/models/Lottery";

export default observer(function Place(props: ILotteryPlace) {
    const title = useMemo(() => _.split(_.trim(props.title), /\s/g), [props.title]);

    return (
        <Typography as="div" className={`bl-lottery-ng-place place_${title?.[0] ?? ""}`}>
            <Typography as="div" className="bl-lottery-ng-place__container">
                <Typography as="div" className="bl-lottery-ng-place__container__top">
                    <Typography
                        as="div"
                        className={`bl-lottery-ng-place__container__top__cubok cubok_${
                            title?.[0] ?? ""
                        }`}
                    ></Typography>
                    <Typography as="div" className="bl-lottery-ng-place__container__top__place">
                        {_.chain(title)
                            .map((i, k) => (
                                <Typography
                                    key={k}
                                    as="div"
                                    className={`${!k ? `num num_${i}` : "text"}`}
                                >
                                    {i}
                                </Typography>
                            ))
                            .valueOf()}
                    </Typography>
                </Typography>
            </Typography>
            <Typography as="div" className="bl-lottery-ng-place__prize">
                <Typography as="div" className="bl-lottery-ng-place__prize__sum">
                    {props.sum}
                </Typography>
            </Typography>
        </Typography>
    );
});
