import {Button, Form, Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import {t} from "i18next";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import "./style.scss";
//Store
//components
import {InterceptorContext, LayoutContext, ModalContext} from "../../../../../core/Contexts";
import {FormInstance} from "antd";
import SpaceAutoPaymentAmount from "../autopaymentDefaultCreate/spacePaymentSum";
import PhoneField from "../../../../fields/phone";
import _ from "lodash";
import {BankListModal} from "../../../../modal/bankListModal";
import useFormHandler from "../../../../../core/hooks/useFormHandler";
import useOnChange from "../../../../../core/hooks/useOnChange";

export default observer(function AutopaymentSBP({
    form,
    isMainPage,
    number,
    tariffName,
    cancelHandler,
    setApiErrors,
    setState
}: TProps) {
    const context = useContext(InterceptorContext);
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const formInstance = useFormHandler(form);
    const onChange = useOnChange(formInstance);
    function isNumberPhone(phone: string) {
        return number ? true : !!phone && !(_.replace(phone!, /(\+7|\s|-)/gi, "").length < 10);
    }

    const onFinish = async (props: {phone: string | number; sum_payment: string | number}) => {
        try {
            const phone = isMainPage
                ? +_.replace(props.phone.toString(), /(\+7|\s|-)/gi, "")
                : number;
            cancelHandler();
            contextModal?.setVisible(true);
            contextModal?.setCssClass(" bl-modal-bank-list");
            contextModal?.setChildren(<BankListModal phone={phone} sum={props.sum_payment} />);
        } catch (err: any) {
            if (!!err.response.data.name) {
                setApiErrors(err.response.data.message);
                setState("error");
            }
        }
    };

    return (
        <Typography as="div" className="autopayment-modal--description-sbp">
            <Title
                color="#000"
                fontFamily="hauss"
                size={_.gte(contextLayout?.width ?? 0, 490) ? 24 : 17}
            >
                Оплата через СБП
            </Title>
            <Form
                form={form}
                layout="horizontal"
                name="autopayment-form--description-defaultCreate"
                onFinish={onFinish}
                onFieldsChange={onChange}
                autoComplete="off"
                initialValues={{
                    phone: isMainPage ? "" : number,
                    sum_payment: null
                }}
            >
                {isMainPage ? (
                    <PhoneField />
                ) : (
                    <Typography
                        as="p"
                        className="autopayment-form--description-defaultCreate-space"
                        color="#000000"
                    >
                        {t("Оплата номера ")} <span>{number}</span>
                        <br />
                        {t(" по тарифу ")}
                        {tariffName}
                    </Typography>
                )}
                <Typography
                    as="div"
                    className="autopayment-form--description-defaultCreate-wrapper"
                >
                    <Space size={10} direction="vertical">
                        <Space size={4} direction="vertical">
                            <Space size={21} direction="vertical">
                                <SpaceAutoPaymentAmount form={form} />
                            </Space>
                        </Space>
                    </Space>
                </Typography>
                <Form.Item noStyle shouldUpdate>
                    {({getFieldsValue, getFieldsError}) => {
                        const {phone, sum_payment} = getFieldsValue();
                        const errors = form
                            .getFieldsError()
                            .some((el: {errors: string | any}) => el.errors.length);

                        const formIsComplete = isNumberPhone(phone) && !!sum_payment && !errors;

                        return (
                            <Button
                                type={"primary"}
                                htmlType="submit"
                                disabled={!formIsComplete}
                                loading={context?.loadingBtn}
                                className="autopayment-modal--cancel-btn-defaultCreate"
                            >
                                {t("Оплатить")}
                            </Button>
                        );
                    }}
                </Form.Item>
            </Form>
        </Typography>
    );
});

type TProps = {
    isMainPage: boolean;
    form: FormInstance<any>;
    tariffName?: string;
    number?: number;
    cancelHandler: () => void;
    setApiErrors: React.Dispatch<React.SetStateAction<null>>;
    setState: React.Dispatch<
        React.SetStateAction<"default" | "defaultCreate" | "success" | "error">
    >;
};
