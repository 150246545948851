import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import {isPhoneAndLogin} from "../../forms/login";
// rules
import {Rules} from "../../forms/rules";

export default observer(function LoginField(props: FormItemProps<any>) {
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className="field-login"
            name="login"
            rules={
                isPhoneAndLogin.isPhone
                    ? [Rules.required()]
                    : [
                          Rules.required(),
                          Rules.min(3),
                          Rules.max(100),
                          Rules.regex(
                              new RegExp(new RegExp(/^[^а-я]*$/i)),
                              "Разрешенные символы: буквы латинского алфавита, цифры, спецсиволы"
                          )
                      ]
            }
        >
            {isPhoneAndLogin.isPhone ? (
                <NumberFormat<any>
                    customInput={Input}
                    autoComplete="new-phone"
                    format={(value) => {
                        if (_.isEqual(value[0], "7") || _.isEqual(value[0], "8")) {
                            value = value.substring(1);
                        }
                        if (!_.size(_.trim(value))) {
                            return "";
                        } else {
                            if (!_.isEqual(value[0], "9")) {
                                value = "9";
                            }

                            const code = value.substring(0, 3);
                            const nums1 = value.substring(3, 6);
                            const nums2 = value.substring(6, 8);
                            const nums3 = value.substring(8, 10);

                            return `+7 ${code}${nums1 ? ` ${nums1}` : ""}${
                                nums2 ? ` ${nums2}` : ""
                            }${_.gt(_.size(value), 8) ? `-${nums3}` : ""}`;
                        }
                    }}
                    title={t("Номер телефона")}
                />
            ) : (
                <Input title={t("Логин")} autoComplete="new-password" />
            )}
        </Form.Item>
    );
});
